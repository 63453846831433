import React, {FC, HTMLAttributes} from 'react'
import {
  SiteRefillsReportDocument,
  SiteRefillsReportDownloadDocument,
  SiteRefillsReportDownloadQuery,
  SiteRefillsReportQuery
} from "$typings/graphql-codegen";
import {
  SiteRefillsReport_reports_siteRefills_data,
  SiteRefillsReportVariables,
  Reports,
  SortDirection
} from "$typings/graphql";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {ColumnDefinition} from "$pages/reports-react/column-definition";
import {SortObject, useGenericReport} from "$pages/reports-react/generic-report";
import {ReportGridDataHook} from "$pages/reports-react/generic-report-hooks";
import {formatDate} from "$lib/dateHelpers";
import {ColumnWidth} from "$pages/reports-react/ColumnWidth";

enum ColumnKey {
  Site = 'site',
  ShipTo = 'shipTo',
  RefillStart = 'refillStart',
  Litres = 'litres',
  Product = 'product',
}

type ReportResult = SiteRefillsReportQuery
type ReportVariables = SiteRefillsReportVariables
type ReportData = SiteRefillsReport_reports_siteRefills_data
type ReportDownloadResult = SiteRefillsReportDownloadQuery

const SiteRefillsReport: FC<HTMLAttributes<HTMLElement>> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Title: string = 'Site refills'
  const Filename: string = 'site-refills-report'
  
  const DefaultSortSettings: SortObject =  {
    sortColumnKey: ColumnKey.Site,
    sortDirection: SortDirection.Asc
  }
    
  function CastDate(value: Date | number | string | null): Date {
    return new Date(value ?? '')
  }
  
  function formatDateString(value: string | null): string {
    return formatDate((CastDate)(value), true, '.')
  }
  
  const ColumnDefinitions: ColumnDefinition<ReportData>[] = [
    new ColumnDefinition<ReportData>(
      t('UI_Reports_Site'),
      (row: ReportData) => String(row[ColumnKey.Site]),
      ColumnKey.Site,
      ColumnWidth.Common.SiteName,
      (row: ReportData) => `/sitedetails/${row.siteId}`
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_ShipTo'),
      (row: ReportData) => String(row[ColumnKey.ShipTo]),
      ColumnKey.ShipTo,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_RefillStart'),
      (row: ReportData) => formatDateString(row[ColumnKey.RefillStart]),
      ColumnKey.RefillStart,
      ColumnWidth.Generic.DateTime
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_Litres'),
      (row: ReportData) => String(row[ColumnKey.Litres]),
      ColumnKey.Litres,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_Product'),
      (row: ReportData) => String(row[ColumnKey.Product]),
      ColumnKey.Product,
      ColumnWidth.Generic.Medium
    ),
  ]
  
  const { GenericReport } = useGenericReport<
    ReportResult,
    ReportVariables,
    ReportData,
    ReportDownloadResult
  >()
  
  function createTitle(data: ReportGridDataHook<ReportData>, variables?: ReportVariables): JSX.Element {
    const title: JSX.Element = <div>{ Title }</div>
    let subTitle: JSX.Element | null = null
    if (variables?.input) {
      const n: number = data.data?.length ?? 0
      const CastDate = (v: Date | string | number | null) => new Date(v ?? '')
      const fmt = (v: Date) => formatDate(v, false, '.')
      const fromDate: Date = (CastDate)(variables?.input?.fromDate)
      const toDate: Date = (CastDate)(variables?.input?.toDate)
      const from: string = fmt(fromDate)
      const to: string = fmt(toDate)
      subTitle = <div>{ t('UI_Reports_RefillsForPeriod') + `: ${from} - ${to} (${n} items)` }</div>
    }
    const displaySubTitle: boolean | undefined = variables?.input && !data.isLoading
    return (
      <div className="flex flex-col gap-4">
        { title }
        { displaySubTitle && subTitle }
      </div>
    )
  }
  
  function dataSelector(result: ReportResult): ReportData[] {
    return result?.reports?.siteRefills?.data as ReportData[] ?? []
  }
  
  function dataCounter(result: ReportResult): number {
    return dataSelector(result).length
  }
  
  function downloadUrlSelector(result: ReportDownloadResult): string {
    return result?.reports?.siteRefills?.blobUrl ?? ''
  }
  
  return <GenericReport
    titleCallback={createTitle}
    reportEnum={Reports.SiteRefills}
    mainQueryDocument={SiteRefillsReportDocument}
    downloadQueryDocument={SiteRefillsReportDownloadDocument}
    columnDefinitions={ColumnDefinitions}
    dataSelector={dataSelector}
    dataCounter={dataCounter}
    defaultSortSettings={DefaultSortSettings}
    downloadUrlSelector={downloadUrlSelector}
    downloadFilename={Filename}    
    allowRunningUnfiltered={false} />
}

export default SiteRefillsReport

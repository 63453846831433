import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";
import SiteRefillsReport from "$pages/reports-react/site-refills-report/site-refills-report";

class SiteRefillsReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(SiteRefillsReport);
  }
}

export default SiteRefillsReportReactWrapper
